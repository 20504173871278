export const ucwords = (str, force = false) =>
    str
        ? (force ? str.toLowerCase(str) : str).replace(/^(.)|\s+(.)/g, ($1) => $1.toUpperCase())
        : '';

export const isMicrosoft = () =>
    new RegExp(['MSIE ', 'Trident/', 'Edge/'].join('|')).test(window.navigator.userAgent);

export default () => {
    throw new Error(
        'Utility has no default export. You have probably tried to import a utility function without the curly brackets.'
    );
};
