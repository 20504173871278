import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './PrimaryNavigationTitle.module.scss';

const PrimaryNavigationTitle = ({ isOpen, onClick }) => (
    <h2 className={styles.root} onClick={() => onClick()}>
        <span>Main Menu</span>
        <i className={classNames(styles.icon, isOpen ? styles.iconOpen : null)} />
    </h2>
);

PrimaryNavigationTitle.propTypes = {
    isOpen: PropTypes.bool,
    onClick: PropTypes.func,
};

PrimaryNavigationTitle.defaultProps = {
    isOpen: false,
    onClick: () => null,
};

export default PrimaryNavigationTitle;
