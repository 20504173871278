import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ucwords } from '../../utility';

import Container from '../Container';

import styles from './Section.module.scss';

const getBackgroundColor = (background) =>
    `rootBg${ucwords(Array.isArray(background) ? background[0] : background)}`;

const Section = ({
    title,
    figure,
    extra,
    actions,
    background,
    color,
    isBaseless,
    isCentered,
    isFullWidth,
    isReverse,
    hasContent,
    hasDescription,
    hasLogo,
    children,
}) => {
    const rootClass = classNames(
        styles.root,
        background ? styles[getBackgroundColor(background)] : null,
        color ? styles[`root${ucwords(color)}`] : null,
        isBaseless ? styles.rootBaseless : null,
        isCentered ? styles.rootCentered : null,
        hasLogo ? styles.rootHasLogo : null
    );

    const containerClass = classNames(
        isFullWidth ? null : styles.container,
        isReverse ? styles.containerReverse : null
    );

    const contentClass = hasContent ? styles.content : null;

    const rootStyle =
        Array.isArray(background) && background[1]
            ? { backgroundImage: `url(${background[1]})` }
            : null;

    return (
        <section className={rootClass} style={rootStyle}>
            <Container className={containerClass}>
                <div className={contentClass}>
                    {title}
                    {hasDescription ? (
                        <div className={styles.description}>{children}</div>
                    ) : (
                        children
                    )}
                </div>
                {figure}
            </Container>
            {extra}
            {Boolean(actions) && <p className={styles.actions}>{actions}</p>}
        </section>
    );
};

Section.propTypes = {
    title: PropTypes.string.isRequired,
    figure: PropTypes.node,
    extra: PropTypes.node,
    actions: PropTypes.node,
    background: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    color: PropTypes.string,
    isBaseless: PropTypes.bool,
    isCentered: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    isReverse: PropTypes.bool,
    hasContent: PropTypes.bool,
    hasDescription: PropTypes.bool,
    hasLogo: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

Section.defaultProps = {
    figure: null,
    extra: null,
    actions: null,
    background: null,
    color: null,
    isBaseless: false,
    isCentered: false,
    isFullWidth: false,
    isReverse: false,
    hasContent: true,
    hasDescription: false,
    hasLogo: false,
};

export default Section;
