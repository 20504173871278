import React from 'react';

import Section from '../Section';
import SectionTitle from '../SectionTitle';
import ContactList from '../ContactList';

import styles from './Contact.module.scss';

const Contact = () => (
    <Section
        title={<SectionTitle>Get in Touch</SectionTitle>}
        background="xdark"
        color="white"
        isCentered
        isFullWidth
    >
        <div className={styles.root} id="contact">
            <p>
                <span>
                    Got any questions or queries about my approach, or whether I&#39;m the right
                    person to help you?
                </span>
                <br />
                <span>Please do not hesitate to get in contact at:</span>
                <span className={styles.email}>
                    <a href="mailto:rehab2robust@gmail.com">rehab2robust@gmail.com</a>
                </span>
            </p>
        </div>
        <ContactList />
    </Section>
);

export default Contact;
