import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import routes from '../../config/routes';

import MiniNavigationItem from './MiniNavigationItem';

import styles from './MiniNavigation.module.scss';

const MiniNavigation = ({ isScrolled }) => (
    <ul
        className={classNames(styles.root, isScrolled ? styles.rootScrolled : null)}
        aria-hidden="true"
    >
        {routes.map((route) => (
            <MiniNavigationItem key={route.route} {...route} />
        ))}
    </ul>
);

MiniNavigation.propTypes = {
    isScrolled: PropTypes.bool,
};

MiniNavigation.defaultProps = {
    isScrolled: false,
};

export default MiniNavigation;
