import React, { useState } from 'react';
import classNames from 'classnames';

import PrimaryNavigationTitle from './PrimaryNavigationTitle';
import PrimaryNavigationItem from './PrimaryNavigationItem';

import routes from '../../config/routes';

import styles from './PrimaryNavigation.module.scss';

const PrimaryNavigation = () => {
    const [isOpen, setOpen] = useState(false);

    const toggleOpen = () => setOpen(!isOpen);

    return (
        <nav className={styles.root}>
            <PrimaryNavigationTitle isOpen={isOpen} onClick={toggleOpen} />
            <ul className={classNames(styles.list, isOpen ? styles.listOpen : null)}>
                {routes.map((route) => (
                    <PrimaryNavigationItem
                        key={route.route}
                        isOpen={isOpen}
                        setOpen={setOpen}
                        {...route}
                    />
                ))}
            </ul>
        </nav>
    );
};

export default PrimaryNavigation;
