import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ucwords } from '../../utility';

import styles from './SectionTitle.module.scss';

const SectionTitle = ({ color, number, children }) => {
    const rootClass = classNames(styles.root, color ? styles[`root${ucwords(color)}`] : null);

    return (
        <h2 className={rootClass}>
            {children}
            {Boolean(number) && <i className={styles.number}>{number}</i>}
        </h2>
    );
};

SectionTitle.propTypes = {
    color: PropTypes.string,
    number: PropTypes.string,
    children: PropTypes.node.isRequired,
};

SectionTitle.defaultProps = {
    color: null,
    number: null,
};

export default SectionTitle;
