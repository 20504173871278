import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';

import styles from './MiniNavigationItem.module.scss';

const MiniNavigationItem = ({ label, route }) => (
    <li className={styles.root}>
        {route.match(/^#/u) ? (
            <a href={route} className={styles.link}>
                {label}
            </a>
        ) : (
            <Link to={route} className={styles.link}>
                {label}
            </Link>
        )}
    </li>
);

MiniNavigationItem.propTypes = {
    label: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
};

export default MiniNavigationItem;
