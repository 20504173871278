import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ucwords } from '../../utility';

import Banner from '../Banner';
import Contact from '../Contact';

import '../../styles/elements.scss';
import '../../styles/layout.scss';

import styles from './Layout.module.scss';

const Layout = ({ type, children }) => {
    const contentClass = classNames(
        styles.content,
        type ? styles[`content${ucwords(type)}`] : null
    );

    return (
        <Fragment>
            <Helmet>
                <title>Rehab2Robust</title>
                <link
                    href="https://fonts.googleapis.com/css?family=Open+Sans:400,600|Teko:300"
                    rel="stylesheet"
                />
            </Helmet>
            <Banner />
            <div className={contentClass}>{children}</div>
            <Contact />
        </Fragment>
    );
};

Layout.propTypes = {
    type: PropTypes.string,
    children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
    type: null,
};

export default Layout;
