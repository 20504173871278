const routes = [
    {
        label: 'Home',
        route: '/',
    },
    {
        label: 'Services & Pricing',
        route: '/services',
    },
    {
        label: 'Testimonials',
        route: '/testimonials',
    },
    {
        label: 'Contact',
        route: '#contact',
    },
];

export default routes;
