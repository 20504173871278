import React from 'react';

import ContactListItem from './ContactListItem';

import styles from './ContactList.module.scss';

const data = [
    {
        id: 'fb',
        title: 'Facebook',
        url: 'https://www.facebook.com/rehab2robust',
    },
    {
        id: 'in',
        title: 'Instagram',
        url: 'https://www.instagram.com/rehab2robust',
    },
    {
        id: 'tw',
        title: 'Twitter',
        url: 'https://twitter.com/AKraz88',
    },
];

const ContactList = () => (
    <ul className={styles.root}>
        {data.map((item) => (
            <ContactListItem key={item.id} {...item} />
        ))}
    </ul>
);

export default ContactList;
