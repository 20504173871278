import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ucwords } from '../../../utility';

import styles from './ContactListItem.module.scss';

const ContactListItem = ({ id, title, url }) => {
    const linkClass = classNames(styles.link, styles[`link${ucwords(id)}`]);

    return (
        <li className={styles.root}>
            <a href={url} className={linkClass} target="_blank" rel="noopener noreferrer">
                {title}
            </a>
        </li>
    );
};

ContactListItem.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default ContactListItem;
