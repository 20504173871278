import React from 'react';
import PropTypes from 'prop-types';

import Container from '../Container';

import styles from './Hero.module.scss';

const Hero = ({ title, image }) => (
    <section className={styles.root} style={{ backgroundImage: `url(${image})` }}>
        <Container className={styles.container}>
            <h2 className={styles.title}>{title}</h2>
        </Container>
        <i className={styles.logo} />
    </section>
);

Hero.propTypes = {
    title: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
};

export default Hero;
