import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';

import styles from './PrimaryNavigationItem.module.scss';

const PrimaryNavigationItem = ({ label, route, isOpen, setOpen }) => (
    <li className={classNames(styles.root, isOpen ? styles.rootOpen : null)}>
        {route.match(/^#/u) ? (
            <a href={route} className={styles.link} onClick={() => setOpen(false)}>
                {label}
            </a>
        ) : (
            <Link to={route} className={styles.link}>
                {label}
            </Link>
        )}
    </li>
);

PrimaryNavigationItem.propTypes = {
    label: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
    isOpen: PropTypes.bool,
    setOpen: PropTypes.func,
};

PrimaryNavigationItem.defaultProps = {
    isOpen: false,
    setOpen: () => null,
};

export default PrimaryNavigationItem;
