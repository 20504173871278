import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import Container from '../Container';
import Logo from '../Logo';
import PrimaryNavigation from '../PrimaryNavigation';
import MiniNavigation from '../MiniNavigation';

import styles from './Banner.module.scss';

const Banner = () => {
    const TARGET_OFFSET = 50;
    const [isScrolled, setScrolled] = useState(false);

    useEffect(() => {
        let animationFrame = null;

        const loop = () => {
            setScrolled(window.pageYOffset > TARGET_OFFSET);
            animationFrame = requestAnimationFrame(loop);
        };

        loop();

        return () => {
            cancelAnimationFrame(animationFrame);
        };
    }, []);

    return (
        <header className={classNames(styles.root, isScrolled ? styles.rootScrolled : null)}>
            <Container className={styles.container}>
                <Logo isScrolled={isScrolled} />
                <PrimaryNavigation />
                <MiniNavigation isScrolled={isScrolled} />
            </Container>
        </header>
    );
};

export default Banner;
