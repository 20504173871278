import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';

import styles from './Logo.module.scss';

const Logo = ({ isScrolled }) => (
    <h1>
        <Link
            to="/"
            rel="home"
            className={classNames(styles.root, isScrolled ? styles.rootScrolled : null)}
        >
            Rehab2Robust
        </Link>
    </h1>
);

Logo.propTypes = {
    isScrolled: PropTypes.bool,
};

Logo.defaultProps = {
    isScrolled: false,
};

export default Logo;
